<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewDreamerSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-dreamer-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('forms.add_model', { model: $t('models.dreamer') }) }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- AVATAR -->
          <div class=" text-center" style="width: 140px; margin: auto;
          background-color: #d6ebff;
          border-radius: 10px;">
            <b-carousel id="carousel-example-generic" ref="myCarousel" :interval="0" v-model="avatar_slide">
              <b-carousel-slide v-for="i in 133" :key="i"
                :img-src="require('@/assets/images/avatar/AvatarSprite_' + i + '.png')" />
            </b-carousel>
          </div>

          <div class="d-flex align-items-center justify-content-center mt-1">
            <b-button-group>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="prev">
                <feather-icon icon="ChevronLeftIcon" size="16" />
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="next">
                <feather-icon icon="ChevronRightIcon" size="16" />
              </b-button>
            </b-button-group>
          </div>
          <!-- Name -->
          <validation-provider 
          #default="validationContext" 
          :name="$t('fields.name')"     
          rules="required|max:255"             
          :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group :label="$t('fields.name')" label-for="name">
              <b-form-input id="name" v-model="recordData.name" autofocus :state="getValidationState(validationContext)"
                trim/>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- BIRTHDATE -->
          <validation-provider 
          #default="validationContext" 
          :name="$t('fields.f_nac')" 
          rules="required"
          :custom-messages="{ required: $t('validators.required') }"
          >
            <b-form-group :label="$t('fields.f_nac')" label-for="birthdate">
              <b-form-datepicker id="birthdate" v-model="recordData.birthdate" :locale="getLocal" :max="max"
                start-weekday="1" :state="getValidationState(validationContext)" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- GROUP -->
          <b-form-group :label="$t('fields.group')" label-for="group" v-if="$store.getters['user/getRole'] != 'teacher'">
            <v-select v-model="linked_groups" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="name"
              :options="groups" />
          </b-form-group>

          <!-- NEE -->
          <div>
            <p class="mb-1">
              <b-form-checkbox checked="false" class="custom-control-info" name="check-button" switch v-model="has_nee"
                style="display:inline;" />
              {{ $t('forms.dreamer_has_nee') }}
            </p>
          </div>
          <div v-if="has_nee">
            <b-form-group>

              <b-form-radio-group style="margin-bottom:5px;" v-model="recordData.nee_selected" name="nee" @change="onRadioChange">
                <div v-for="option in nee" :key="option.id">
                  <b-form-radio :value="option.id" class="custom-radio">
                    {{ $t('special_needs.' + option.name) }}
                  </b-form-radio>
                </div>
              </b-form-radio-group>

            </b-form-group>
            <div>
              <template v-if="recordData.nee_selected && recordData.nee_selected.length == 1">
                <span v-html="$t('dreamer.has_one_nee_settings')"></span>
              </template>
            </div>

            <!-- RECOMMENDED SETTINGS -->
            <div class="alert-body"
              v-if="recordData.nee_selected && recordData.nee_selected != null && recordData.nee_selected != '' && recordData.nee_selected != 0 && recordData.nee_selected.length != 0">
              <b-row>
                <b-col cols="12">
                  <b-card>
                    <p v-for="v, k in showSettings(recordData.nee_selected)" :key="k" style="margin-bottom:0;">
                      <i class="fas fa-angle-right"></i>
                      <span style="font-size:14px; font-weight:bolder;">{{ $t('customize.' + k) }}: </span>
                      <span v-for="item, idx in v" :key="item">{{ $t(settings_info[k][item]) }}{{ v.length > idx + 1 ? ',':'.'}}</span>
                    </p>
                  </b-card>
                  <b-form-checkbox style="margin-bottom:2rem;" v-model="apply_preset_customization"
                    name="apply_preset_customization">
                    {{ $t('dreamer.apply_settings') }}
                  </b-form-checkbox>

                </b-col>

              </b-row>
            </div>
          </div>

          <!-- PASSWORD -->
          <div>
            <p class="mb-1">
              <b-form-checkbox checked="false" class="custom-control-info" name="check-button" switch v-model="acc_pass"
                style="display:inline;" />
              {{ $t('forms.dreamer_has_pass') }}
            </p>
          </div>
          <div v-if="acc_pass">
            <div class="row childpassword">
              <div class="col-md-12">
                <div class="childpassword__container childpassword__container--selector">
                  <template v-for="index in 9">
                    <div :key="index" @click="addPassword(index)" class="childpassword__item childpassword__item--button">
                      <b-img :src="require('@/assets/images/passwords/' + index + '.png')" />
                    </div>
                  </template>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="childpassword__container childpassword__container--result">
                    <template v-for="(p, index) in recordData.child_password">
                      <div :key="index" class="childpassword__item">
                        <b-img :src="require('@/assets/images/passwords/' + p + '.png')" />
                        <span class="order">{{ index + 1 }}</span>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="row">
                  <div class=" childpassword__delete">
                    <button type="button" @click="emptyPassword" class="btn btn-danger btn-sm">
                      {{ $t('forms.delete') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- TOPICS -->
          <!-- <div>
            <p class="mb-1">
              <b-form-checkbox
                checked="false"
                class="custom-control-info"
                name="check-button"
                switch
                v-model="has_topics"
                style="display:inline;"
              />
              {{ $t('forms.dreamer_has_topics')}}
            </p>
          </div>
          <div v-if="has_topics">
            <b-form-group>
              
              <b-form-checkbox 
                v-for="option in topics"
                :value="option.id"
                v-model="recordData.topics_selected"
                name="topic"
                :key="option.id">
                  {{ $t('topic.'+option.name) }}
              </b-form-checkbox>
                
            </b-form-group>
          </div> -->



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ $t('forms.add') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              {{ $t('forms.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { createDreamer } from '@/api/routes'

import {
  BRow, BCol,
  BCarousel, BCarouselSlide,
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BFormDatepicker,
  BFormCheckbox,
  BFormCheckboxGroup, BCardText, BCard,
  BImg, BFormRadioGroup, BFormRadio,
  BButtonGroup,
  BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol,
    BFormDatepicker,
    BFormCheckbox,
    BFormCheckboxGroup, BCardText, BCard,
    BCarousel, BCarouselSlide,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BButtonGroup,
    vSelect,
    BFormRadioGroup, BFormRadio,
    BImg,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDreamerSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    preselected_group: {
      type: Array,
      required: false
    },
    isAddNewDreamerSidebarActive: {
      type: Boolean,
      required: true,
    },
    groups: {
      type: Array,
      required: true
    },
    nee: {
      type: Array,
      required: true
    },
    topics: {
      type: Array,
      required: true
    }
  },
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    return {
      max: maxDate,

      has_nee: false,
      has_topics: false,
      acc_pass: false,
      avatar_slide: 56,
      linked_groups: this.preselected_group ? this.preselected_group : [],
      required,
      settings_info: {
        'difficulty_level': {
          0: 'apps_levels.apprentice',
          1: 'apps_levels.intermediate',
          2: 'apps_levels.advanced',
          3: 'apps_levels.expert',
        },
        'no_rush': {
          0: 'customize.deactive',
          1: 'customize.active',
        },
        'reading_mode': {
          0: 'customize.read',
          1: 'customize.listen',
          2: 'customize.read_listen',
          3: 'customize.pictograms',
        },
        'font_type': {
          0: 'customize.standard',
          1: 'customize.linked',
          2: 'customize.capital',
          4: 'customize.dyslexic'
        },
        'accessibility_settings': {
          1: 'customize.pagination',
          2: 'customize.highlighting',
          3: 'customize.speech_synthesis',
          4: 'customize.speech_recognition',
          5: 'customize.subtitles',
        },
        'world_visualization': {
          1: 'world_visualization.space',
          2: 'world_visualization.islands',
          3: 'world_visualization.bigSpecial',
          4: 'world_visualization.smallSpecial',
          5: 'world_visualization.pixel3D',
          6: 'world_visualization.small',
          7: 'world_visualization.youth',
        }
      },
    }
  },

  setup(props, { emit }) {
    const blankrecordData = {
      name: '',
      avatar: 56,
      birthdate: '',
      topics_selected: [],
      nee_selected: [],
      apply_preset_customization: false,

      child_password: []

    }

    const recordData = ref(JSON.parse(JSON.stringify(blankrecordData)))
    const resetrecordData = () => {
      recordData.value = JSON.parse(JSON.stringify(blankrecordData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrecordData)

    return {
      recordData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  computed: {
    getLocal() {
      return this.$store.getters['user/getLang']
    },
  },
  watch: {
    has_nee() {
      this.apply_preset_customization = false
      this.recordData.nee_selected = []

    },
    'recordData.nee_selected': {
      handler: function () {
        /* when special needs selection changes, uncheck apply config */
        this.apply_preset_customization = false
      },
      deep: true
    }
  },
  methods: {
    onRadioChange(value) {
      this.recordData.nee_selected = value;
      this.apply_preset_customization = false;
    },
    showSettings(nee_name) {
      const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

      let b_day = this.recordData.birthdate;
      let age = getAge(b_day)

      let wv = [1];
      if (nee_name == null) {
        //no NEE
        if (age <= 7) {
          wv = [2]
        } else if (age >= 10) {
          wv = [5]
        }
      } else {
        if (age > 7) {
          wv = [3]
        } else {
          wv = [4]
        }
      }

      switch (nee_name) {
        case 1:// "attention_deficit_hyperactivity_disorder": 
          let settings_1 = {}
          settings_1['world_visualization'] = wv
          settings_1['reading_mode'] = [2]
          settings_1['no_rush'] = [1]
          //settings_1['accessibility_settings'] = [2]
          return settings_1
        case 2://"autism_spectrum_disorder":
          let settings_2 = {}
          settings_2['world_visualization'] = wv
          settings_2['reading_mode'] = [3]
          settings_2['font_type'] = [2]
          settings_2['no_rush'] = [1]
          //settings_2['accessibility_settings'] = [1,2]
          return settings_2
        case 3://"intellectual_disability": 
          let settings_3 = {}
          settings_3['world_visualization'] = wv
          settings_3['reading_mode'] = [3]
          settings_3['font_type'] = [2]
          settings_3['no_rush'] = [1]
          //settings_3['accessibility_settings'] = [1,2,3]
          return settings_3
        case 4://"motor_impairment":
          let settings_4 = {}
          settings_4['world_visualization'] = wv
          settings_4['reading_mode'] = [2]
          settings_4['font_type'] = [2]
          settings_4['no_rush'] = [1]
          //settings_4['accessibility_settings'] = [1,2,3]
          return settings_4
        case 5://"hearing_impairment":
          let settings_5 = {}
          settings_5['reading_mode'] = [0]
          settings_5['font_type'] = [0]
          settings_5['no_rush'] = [1]
          //settings_5['accessibility_settings'] = [5]
          return settings_5
        case 6://"visual_impairment":
          let settings_6 = {}
          settings_6['reading_mode'] = [1]
          settings_6['no_rush'] = [1]
          settings_6['accessibility_settings'] = [1]
          return settings_6
        case 7://"learning_disorders": 
          let settings_14 = {}
          settings_14['reading_mode'] = [2]
          settings_14['font_type'] = [0]
          settings_14['no_rush'] = [1]
          //settings_14['accessibility_settings'] = [2]
          return settings_14
        case 8://"language_delays": //dyslexia
          let settings_13 = {}
          settings_13['reading_mode'] = [2]
          settings_13['font_type'] = [4]
          settings_13['no_rush'] = [1]
          //settings_13['accessibility_settings'] = [1,2]
          return settings_13
        default:
          let settings = {}
          settings['world_visualization'] = wv
          settings['reading_mode'] = [2]
          settings['font_type'] = [0]
          settings['no_rush'] = [0]
          return settings
      }
    },
    emptyPassword() {
      this.recordData.child_password = [];
    },
    addPassword(index) {
      if (this.recordData.child_password.length < 3) {
        this.recordData.child_password.push(index);
      }
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async onSubmit() {

      let data = this.recordData
      data.avatar = this.avatar_slide + 1
      data.linked_groups = this.linked_groups
      data.showTopics = this.has_topics
      data.showSpecialNeeds = this.has_nee

      await this.$http.post(createDreamer, data).then(response => {
        if (response.data.status == 200) {
          this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-dreamer-sidebar-active', false)
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg);
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })

    },
    prev() {
      if (!this.status) {
        this.$refs.myCarousel.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.myCarousel.next()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

/* CHILDPASSWORD */
.childpassword {
  text-align: center;
}

.childpassword__container--selector {
  width: 200px;
  height: 200px;
}

.childpassword__container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #b5eff9;
  border-radius: 8px;
}

.childpassword__item--button {
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.childpassword__item {
  height: 60px;
  width: 60px;
  background-size: cover;
}

.childpassword__delete {
  width: 200px;
  height: 30px;
  margin: 20px auto 5px auto;
}

.childpassword__container--result {
  width: 200px;
  height: 80px;
  margin: 20px auto 5px auto;
}

.childpassword__item>.order {
  background-color: #68c500;
  color: white;
  display: block;
  border-radius: 50%;
  position: relative;
  top: -64px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
}
</style>
